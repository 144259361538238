import React, { useContext, useState } from "react";
import {
    Button,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
} from "reactstrap";

import "./styles/Header.css";
import { default as hamburger } from "./resources/hamburger-menu-svgrepo-com.svg";
import { default as gear } from "./resources/gear-settings-svgrepo-com.svg";
import { default as userIcon } from "./resources/user.svg";
import { default as arsLogo } from "./resources/ars-logo-white.svg";
import { default as arsLogoText } from "./resources/ars-logo-white-text.svg";
import { default as chatBubble } from "./resources/speech-bubble.svg";
import { default as question } from "./resources/question-circle.svg";
import { default as utilIcons } from "./resources/utilIcons";

import { APP_NAME, checkMobile } from "./util/globals";
import { getFontScale } from "./util/textUtils";
import { ModalContext, RenderContext } from "./App";
import { ForgotPassword } from "./auth/ForgotPassword";
import ContactsReport from "./ContactsReport";
import { colors } from "./styles/colors";
import DocumentationModal from "./Documentation";

export default function Header({
    user,
    isMobile,
    handleLogout,
    navOpen,
    toggleNav,
    onSelectFeature,
}) {
    const [settingsMenuOpen, setSettingsMenuOpen] = useState(false);
    const [downloadsMenuOpen, setDownloadsMenuOpen] = useState(false);

    const { openModal, setOpenModal } = useContext(ModalContext);
    const { renderControlledComponent } = useContext(RenderContext);

    const toggleSettingsMenuOpen = () => setSettingsMenuOpen(!settingsMenuOpen);
    const toggleDownloadsMenuOpen = () =>
        setDownloadsMenuOpen(!downloadsMenuOpen);
    const togglePasswordModal = () => {
        if (openModal === "change-password") {
            setOpenModal(null);
        } else {
            setOpenModal("change-password");
        }
    };
    const toggleDocumentationModal = () => {
        if (openModal === "documentation-modal") {
            setOpenModal();
        } else {
            setOpenModal("documentation-modal");
        }
    };

    const getLogo = () => {
        if (isMobile) {
            return arsLogo;
        } else {
            return arsLogoText;
        }
    };

    const getHeaderRightSide = () => {
        if (isMobile) {
            return (
                <div id="header-right-wrapper" hidden={user === undefined}>
                    <Dropdown
                        id="settings-dropdown"
                        isOpen={settingsMenuOpen}
                        toggle={toggleSettingsMenuOpen}
                    >
                        <DropdownToggle
                            id="settings-button"
                            className={settingsMenuOpen ? "active" : null}
                            color="primary"
                        >
                            <img
                                src={gear}
                                className="inline-svg"
                                alt="settings"
                            />
                        </DropdownToggle>
                        <MobileSettingsMenu
                            user={user}
                            handleLogout={handleLogout}
                        />
                    </Dropdown>
                    <ForgotPassword
                        isOpen={openModal === "change-password"}
                        toggle={togglePasswordModal}
                    />
                </div>
            );
        } else {
            return (
                <div id="header-right-wrapper" hidden={user === undefined}>
                    <Dropdown
                        id="downloads-dropdown"
                        isOpen={downloadsMenuOpen}
                        toggle={toggleDownloadsMenuOpen}
                    >
                        <DropdownToggle
                            id="downloads-button"
                            className="header-button"
                            color="primary"
                        >
                            {utilIcons.download(colors["ars-neutral-900"])}
                        </DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem
                                onClick={() => setOpenModal("contacts-report")}
                            >
                                Contacts Report
                            </DropdownItem>
                            {renderControlledComponent(
                                <DropdownItem
                                    id="documentation-toggle"
                                    onClick={toggleDocumentationModal}
                                >
                                    Documentation
                                </DropdownItem>
                            )}
                        </DropdownMenu>
                    </Dropdown>
                    <div
                        id="header-username"
                        style={{
                            fontSize: getFontScale(getUsername(user)) + "em",
                        }}
                    >
                        {getUsername(user)}
                    </div>
                    <Dropdown
                        id="settings-dropdown"
                        isOpen={settingsMenuOpen}
                        toggle={toggleSettingsMenuOpen}
                    >
                        <DropdownToggle
                            id="account-button"
                            className={settingsMenuOpen ? "active" : null}
                            color="primary"
                        >
                            <img
                                src={userIcon}
                                className="inline-svg"
                                alt="settings"
                            />
                        </DropdownToggle>
                        <DesktopSettingsMenu
                            user={user}
                            handleLogout={handleLogout}
                        />
                    </Dropdown>
                    <ForgotPassword
                        isOpen={openModal === "change-password"}
                        toggle={togglePasswordModal}
                    />
                    <ContactsReport />
                    <DocumentationModal
                        isOpen={openModal === "documentation-modal"}
                        toggle={toggleDocumentationModal}
                    />
                </div>
            );
        }
    };

    return (
        <header>
            <Dropdown
                id="nav-dropdown"
                isOpen={navOpen}
                toggle={toggleNav}
                hidden={user === undefined}
            >
                <DropdownToggle
                    id="nav-hamburger"
                    className={navOpen ? "active" : null}
                    color="primary"
                >
                    <img
                        src={hamburger}
                        className="inline-svg"
                        alt="navigation"
                    />
                </DropdownToggle>
                <DropdownMenu hidden={!checkMobile()}>
                    <NavItem
                        id="nav-dashboard"
                        text="Dashboard"
                        onClick={onSelectFeature}
                    />
                    <NavItem
                        id="nav-site-status-log"
                        text="Site Status Log"
                        onClick={onSelectFeature}
                        disabled
                    />
                    <NavItem
                        id="nav-station-log"
                        text="Station Log"
                        onClick={onSelectFeature}
                    />
                    <NavItem
                        id="nav-checklist"
                        text="Checklist"
                        onClick={onSelectFeature}
                    />
                    <NavHeader id="nav-data" text="Data" />
                    <NavItem
                        id="nav-stripchart"
                        text="Strip Chart"
                        onClick={onSelectFeature}
                        subItem
                    />
                    <NavItem
                        id="nav-administration"
                        text="Administration"
                        onClick={onSelectFeature}
                    />
                </DropdownMenu>
            </Dropdown>
            <div id="app-title">
                <img
                    src={getLogo()}
                    className="inline-svg"
                    alt="Air Resource Specialists, Inc. Logo"
                />
                <span>{APP_NAME}</span>
            </div>
            {getHeaderRightSide()}
        </header>
    );
}

function NavHeader({ id, text }) {
    const { renderControlledComponent } = useContext(RenderContext);

    return renderControlledComponent(
        <DropdownItem id={id} className="side-nav-section" header>
            {text}
        </DropdownItem>
    );
}

function NavItem({ id, text, onClick, subItem = false, ...props }) {
    const { renderControlledComponent } = useContext(RenderContext);

    return renderControlledComponent(
        <DropdownItem id={id} className={subItem ? "sub" : ""} onClick={onClick} {...props}>
            {text}
        </DropdownItem>
    );
}

function MobileSettingsMenu({ user, handleLogout }) {
    const { setOpenModal } = useContext(ModalContext);

    return (
        <DropdownMenu end>
            <div id="settings-menu-user-wrapper">
                <div
                    id="settings-menu-username"
                    style={{ fontSize: getFontScale(getUsername(user)) + "em" }}
                >
                    {getUsername(user)}
                </div>
                <div id="settings-menu-user-icon">
                    <img src={userIcon} className="inline-svg" alt="account" />
                </div>
            </div>
            <DropdownItem header>Account</DropdownItem>
            <DropdownItem
                parent="Account"
                onClick={() => setOpenModal("change-password")}
            >
                Change password
            </DropdownItem>
            <DropdownItem divider />
            <DropdownItem onClick={handleLogout}>Logout</DropdownItem>
        </DropdownMenu>
    );
}

function DesktopSettingsMenu({ handleLogout }) {
    const { setOpenModal } = useContext(ModalContext);
    return (
        <DropdownMenu end>
            <DropdownItem header>Account</DropdownItem>
            <DropdownItem
                parent="Account"
                onClick={() => setOpenModal("change-password")}
            >
                Change password
            </DropdownItem>
            <DropdownItem divider />
            <DropdownItem onClick={handleLogout}>Logout</DropdownItem>
        </DropdownMenu>
    );
}

function getUsername(user) {
    if (user === undefined) return null;
    return user.fullName.split(" ")[0];
}
