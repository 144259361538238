import React, { useCallback, useContext, useEffect, useState } from "react";
import { Button, Modal, ModalBody, Spinner } from "reactstrap";

import { default as icons } from "../../resources/currentDataIcons";
import { default as utilIcons } from "../../resources/utilIcons";
import { colors } from "../../styles/colors";

import { checkMobile } from "../../util/globals";
import { aqiFields, aqiIndeces, getAQIRating } from "./AQI";
import { DASHBOARD_URL } from "../../serverConfig";
import { RestContext } from "../../App";
import { sortParameters } from "../../util/dashboardUtil";
import { DashboardContext } from "./Dashboard";
import { getTimezoneAbbr } from "../../util/timeUtils";

const displayedParameters = [
    // Parameters with names not listed will not be rendered on the page
    "Ozone",
    "Particulate Matter",
    "Particulate Matter (Low Cost Sensor)",
    "Temperature",
    "Relative Humidity",
    "Precipitation",
    "Solar Radiation",
    "Peak Wind Speed",
    "Visibility",
    "Barometric Pressure",
    "Carbon Monoxide",
    "Nitric Oxide",
    "Nitrogen Dioxide",
    "Oxides of Nitrogen",
    "Nitrogen Dioxide plus Nitric Acid",
    "Sulfur Dioxide",
];

function CurrentData({ selectedSite, metric }) {
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedAQI, setSelectedAQI] = useState();
    const [data, setData] = useState([]);
    const [timestamp, setTimestamp] = useState(null);
    const [loading, setLoading] = useState(false);

    const [refWidth, setRefWidth] = useState(0);
    const ref = useCallback((node) => {
        if (node !== null) {
            const resizeObserver = new ResizeObserver(() => {
                setRefWidth(node.getBoundingClientRect().width);
            });
            resizeObserver.observe(node);
            return () => resizeObserver.disconnect();
        }
    }, []);

    const { sendPostRequest } = useContext(RestContext);
    const { siteDetails } = useContext(DashboardContext);

    const toggleModal = () => setModalOpen(!modalOpen);

    useEffect(() => {
        if (selectedSite !== undefined && selectedSite !== "all") {
            loadData();
        }
    }, [selectedSite, metric]);

    useEffect(() => {
        if (selectedAQI !== undefined) {
            setModalOpen(true);
        }
    }, [selectedAQI]);

    const loadData = () => {
        setLoading(true);
        const formData = new FormData();
        formData.append("metric", metric);
        sendPostRequest(
            DASHBOARD_URL + "/currentData/" + selectedSite,
            formData,
            (response) => {
                setData(
                    response.data.parameters
                        .filter((parameter) =>
                            displayedParameters.includes(parameter.parameter)
                        )
                        .sort(sortResponse)
                );
                setTimestamp(response.data.timestamp);
                setLoading(false);
            },
            (error) => {
                setLoading(false);
            }
        );
    };

    const sortResponse = (a, b) => {
        return sortParameters(a.parameter, b.parameter);
    };

    const convertDataToDataPanel = (parameter) => {
        if (!parameter) return null;
        if (parameter.aqi === null) {
            return (
                <DataPanel
                    parameter={parameter}
                    title={parameter.parameter}
                    data={parameter.data}
                />
            );
        } else {
            return (
                <DataPanel
                    parameter={parameter}
                    title={parameter.parameter}
                    data={parameter.data}
                    aqiValue={parameter.aqi.value}
                    setSelectedAQI={setSelectedAQI}
                />
            );
        }
    };

    const formatTable = () => {
        if (!data || data.length === 0) return null;
        const numCols = Math.max(Math.floor(refWidth / 320), 1);
        const numRows = Math.ceil(data.length / numCols);
        const colWidth = refWidth / numCols;
        const rows = [...Array(numRows).keys()].map((rowNum) => {
            const cells = [...Array(numCols).keys()].map((colNum) => (
                <td
                    key={`current-data-cell-${rowNum}-${colNum}`}
                    style={{ width: colWidth }}
                >
                    {convertDataToDataPanel(data[rowNum * numCols + colNum])}
                </td>
            ));
            return <tr key={"current-data-row-" + rowNum}>{cells}</tr>;
        });
        return (
            <table>
                <tbody>{rows}</tbody>
            </table>
        );
    };

    const getTimestamp = () => {
        if (timestamp === undefined) return null;
        const dateString = new Date(timestamp).toLocaleString();
        const dateArray = dateString.split(/[,:/\s]+/g);
        const timezone = ((siteDetails && siteDetails.timezone) ? getTimezoneAbbr(siteDetails.timezone, true) : "");
        return `${dateArray[3]}:${dateArray[4]} ${
            dateArray[6]
        } ${timezone}, ${dateArray[0]}/${dateArray[1]}/${
            dateArray[2]
        }`;
    };

    const checkNoData = () => {
        return timestamp === null || data.length === 0;
    };

    const getLoading = () => {
        if (loading) {
            return <CurrentDataLoading />;
        }
    };

    return (
        <div id="current-data-wrapper" className="dashboard-widget">
            {getLoading()}
            <div id="current-data-header">
                <div className="dashboard-widget-title">Current Data (Raw)</div>
                <div className="dashboard-widget-disclaimer">
                    * Raw data displayed below
                </div>
                <div id="current-data-timestamp" hidden={checkNoData()}>
                    {getTimestamp()}
                </div>
                <Button
                    id="current-data-refresh"
                    className="dashboard-widget-refresh"
                    onClick={loadData}
                    hidden={checkNoData()}
                >
                    {utilIcons["refresh"](colors["ars-neutral-400"])}
                </Button>
            </div>
            <div id="current-data" ref={ref}>
                {formatTable()}
                <NoData hidden={data.length > 0} />
            </div>
            <AQIModal
                isOpen={modalOpen}
                toggle={toggleModal}
                selectedAQI={selectedAQI}
            />
        </div>
    );
}

function DataPanel({ parameter, setSelectedAQI }) {
    const { getAqiRange } = useContext(DashboardContext);

    const mapData = () => {
        if (parameter === undefined || parameter.data === undefined)
            return null;
        return parameter.data.map((field, index) => (
            <DataSection
                key={"data-section-" + parameter.title + "-" + index}
                title={field.fieldName}
                value={field.value}
                unit={field.unit}
            />
        ));
    };

    const handleClick = () => {
        setSelectedAQI({
            title: parameter.title,
            value: parameter.aqi.value,
            unit: parameter.unit,
            scale: getAqiRange(parameter.aqi.aqiParameter),
        });
    };

    const getIcon = () => {
        if (parameter === undefined || parameter.parameter === undefined)
            return null;
        if (parameter.aqi) {
            const quality = getAQIRating(
                parameter.aqi.value,
                getAqiRange(parameter.aqi.aqiParameter)
            );
            return (
                <div
                    className="current-data-panel-icon has-aqi"
                    style={{
                        backgroundColor: colors["aqi-" + quality.abbr],
                        cursor: "pointer",
                    }}
                    onClick={handleClick}
                >
                    {icons[parameter.parameter](
                        quality.abbr === "moderate" ? "#0D1011" : "#FFFFFF"
                    )}
                </div>
            );
        } else {
            return (
                <div className="current-data-panel-icon">
                    {icons[parameter.parameter]("#BEC0C2")}
                </div>
            );
        }
    };

    return (
        <div
            className={
                "current-data-panel" +
                (parameter.data === undefined ? " no-data" : "")
            }
        >
            {getIcon()}
            <div className="current-data-panel-text">
                <div className="current-data-panel-title">
                    {parameter.parameter}
                </div>
                <div className="current-data-panel-data">{mapData()}</div>
            </div>
        </div>
    );
}

function DataSection({ title, value, unit }) {
    const getValue = () => {
        if (value === undefined || value === null) return "N/A";
        return value === -99 || value === -999 ? "N/A" : value;
    };

    const getUnit = () => {
        if (!unit || value === undefined || value === null) return "";
        return value === -99 || value === -999 ? "" : unit;
    };

    return (
        <div className="data-section">
            <div className="data-section-title" hidden={title === null}>
                {title}
            </div>
            <div
                className={
                    title === null
                        ? "data-section-value-large"
                        : "data-section-value"
                }
            >
                {getValue()}
                <span
                    style={{
                        fontSize: "0.5em",
                        color: "var(--ars-neutral-400)",
                        paddingLeft: "0.2ch",
                    }}
                >
                    {getUnit()}
                </span>
            </div>
        </div>
    );
}

function NoData({ hidden }) {
    return (
        <div
            id="current-data-no-data"
            className="dashboard-no-data"
            hidden={hidden}
        >
            No data available.
        </div>
    );
}

function CurrentDataLoading() {
    return (
        <div id="current-data-loading" className="dashboard-widget-loading">
            <Spinner color="light" />
        </div>
    );
}

function AQIModal({ selectedAQI, toggle, isOpen }) {
    const { aqiColors } = useContext(DashboardContext);

    const mapScale = () => {
        if (selectedAQI === undefined) return null;
        const scale = selectedAQI.scale;
        return scale.map((point, index) => {
            if (index === 0) return null;
            const aqiIndex = aqiIndeces[index];
            if (!aqiIndex) return null;
            const color = "#" + aqiColors[index];
            const range =
                index === scale.length - 1
                    ? `${point}+`
                    : `${point} - ${scale[index + 1] - 0.1}`;
            return (
                <div
                    className="aqi-modal-section"
                    key={"aqi-modal-section-" + index}
                >
                    <div className="aqi-modal-section-dot">
                        {icons["dot"](
                            color,
                            `${aqiIndex.text} (${aqiIndex.color})`
                        )}
                    </div>
                    <div className="aqi-modal-section-text">
                        <div className="aqi-modal-section-header">
                            {aqiIndex.text +
                                ` (${range}${
                                    selectedAQI.unit === undefined
                                        ? ""
                                        : " " + selectedAQI.unit
                                })`}
                        </div>
                        <div className="aqi-modal-section-desc">
                            {aqiIndex.desc}
                        </div>
                    </div>
                </div>
            );
        });
    };

    const aqiBadgeStyles = () => {
        if (selectedAQI === undefined) return null;
        const rating = getAQIRating(selectedAQI.value, selectedAQI.scale);
        let styles = {
            backgroundColor: "#" + aqiColors[rating.index],
            color: rating.abbr === "moderate" ? "#0D1011" : "#FFFFFF",
        };

        return styles;
    };

    const getTitle = () => {
        if (selectedAQI === undefined) return null;
        return selectedAQI.title;
    };

    const getValue = () => {
        if (
            !selectedAQI ||
            !selectedAQI.value ||
            selectedAQI.value === -99 ||
            selectedAQI.value === -999
        )
            return "N/A";
        return selectedAQI.value;
    };

    const getBlurb = () => {
        if (checkMobile()) return null;
        return (
            <div id="aqi-modal-nowcast-blurb">
                The NowCast is EPA's method for relating hourly data to the Air
                Quality Index (AQI). It is designed to be responsive to rapidly
                changing air quality conditions, such as during a wildfire. The
                NowCast displays air quality for the current hour by using a
                calculation that involves multiple hours of past data. The
                NowCast aligns more closely with what people are experiencing,
                allowing them to protect their health when air quality is poor
                and help them get outdoors when air quality is good. For ozone,
                the NowCast method uses the relationship between 1-hour and
                8-hour ozone averages during the prior two weeks to predict an
                8-hour ozone average that is centered on the current hour.
            </div>
        );
    };

    return (
        <Modal id="aqi-modal" isOpen={isOpen} toggle={toggle}>
            <ModalBody id="aqi-modal-body">
                <Button
                    className="btn-close"
                    aria-label="Close"
                    onClick={toggle}
                />
                <div id="aqi-modal-current">
                    <div id="aqi-modal-current-name">
                        {getTitle()} NowCast Air Quality Index
                    </div>
                    <div id="aqi-modal-current-value" style={aqiBadgeStyles()}>
                        {getValue()}
                    </div>
                </div>
                <div id="aqi-modal-scale-wrapper">{mapScale()}</div>
                {getBlurb()}
            </ModalBody>
        </Modal>
    );
}

export default CurrentData;
