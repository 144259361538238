import React, { useContext, useEffect, useState } from 'react';
import { Button } from 'reactstrap';

import './styles/main.css';
import { default as leftArrow } from './resources/left-arrow-svgrepo-com.svg';

import { SITE_URL } from './serverConfig';
import DesktopSiteView from './details/DesktopSiteView';
import Dashboard from './features/dashboard/Dashboard';
import MobileSiteView from './details/MobileSiteView';
import StationLog from './features/station-log/StationLog';
import Admin from './features/admin/Admin';
import { RestContext } from './App';
import SiteSelectMenu from './components/SiteSelectMenu';
import SideNav from './SideNav';
import { checkMobile } from './util/globals';
import Checklists from './features/checklist/Checklists';
import Data from './features/data/Data';

export default function Main({ siteDetailsOpen, setSiteDetailsOpen, openFeature, siteList, isMobile, user, navOpen, onSelectFeature }) {
    const [selectedSite, setSelectedSite] = useState('all');
    const [siteDetails, setSiteDetails] = useState();
    const [siteSelectMenuOpen, setSiteSelectMenuOpen] = useState(false);

    const { sendPostRequest } = useContext(RestContext);

    const toggleSiteSelectMenuOpen = () => setSiteSelectMenuOpen(!siteSelectMenuOpen);

    useEffect(() => {
        setSiteDetailsOpen(false);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [openFeature]);

    useEffect(() => {
        if (selectedSite !== undefined) {
            if (selectedSite === "all") {
                setSiteDetailsOpen(false);
                setSiteDetails();
            } else {
                sendPostRequest(SITE_URL + '/details', { siteId: selectedSite }, (response) => {
                    setSiteDetails(response.data);
                });
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedSite]);


    const getOptionText = (site) => {
        if (site.fullName === "All Sites") {
            return site.fullName;
        } else {
            return `${site.siteAbbr}: ${site.fullName}`
        }
    }

    const getOptionValue = (site) => {
        if (site.fullName === "All Sites") {
            return "all";
        } else {
            return site.siteId;
        }
    }

    const mapSiteListToOptions = () => {
        return siteList.map((site, index) =>
            <option key={'site-select-option-' + index} site={site.siteAbbr} value={getOptionValue(site)}>{getOptionText(site)}</option>
        );
    }

    const getRenderedContent = () => {
        if (siteDetailsOpen) {
            if (isMobile) return <MobileSiteView site={siteDetails} user={user} />;
            else return <DesktopSiteView site={siteDetails} user={user} />;
        } else {
            switch (openFeature) {
                case 'dashboard':
                    return <Dashboard selectedSite={selectedSite} siteDetails={siteDetails} />
                case 'station-log':
                    return <StationLog selectedSite={selectedSite} siteDetails={siteDetails} />
                case 'administration':
                    return <Admin selectedSite={selectedSite} />
                case 'checklist':
                    return <Checklists selectedSite={selectedSite} />
                case 'stripchart':
                    return <Data selectedSite={selectedSite} />
                default:
                    return null;
            }
        }
    }

    const handleEnter = (event) => {
        if (event.key === 'Enter') {
            console.log(event.key);
            event.preventDefault();
            toggleSiteSelectMenuOpen();
        }
    }

    const renderSideNav = () => {
        if (checkMobile()) return null;
        return <SideNav isOpen={navOpen} onSelectFeature={onSelectFeature}/>
    }

    return (
        <main>
            {renderSideNav()}
            <div id='main-wrapper'> 
                <div id='site-select-wrapper'>
                    <Button id='return-to-feature-button' onClick={() => setSiteDetailsOpen(false)} hidden={!siteDetailsOpen} color='primary'><img src={leftArrow} className='inline-svg' alt="back" /></Button>
                    <select id='site-select' onMouseDown={(e) => e.preventDefault()} onClick={toggleSiteSelectMenuOpen} onKeyDown={handleEnter} value={selectedSite} disabled={siteList.length === 0}>{mapSiteListToOptions()}</select>
                    <Button id='site-details-button' onClick={() => setSiteDetailsOpen(true)} hidden={siteDetailsOpen || siteDetails === undefined} color='primary'>Details</Button>
                    <SiteSelectMenu isOpen={siteSelectMenuOpen} toggle={toggleSiteSelectMenuOpen} selectedSite={selectedSite} setSelectedSite={setSelectedSite} siteList={siteList} />
                </div>
                <div id='main-content'>
                    {getRenderedContent()}
                </div>
            </div>
        </main>
    )
}