import React, { useContext } from "react";
import { Collapse } from "reactstrap";

import "./styles/SideNav.css";
import { RenderContext } from "./App";

function SideNav({ isOpen, onSelectFeature }) {
    return (
        <Collapse id="side-nav-wrapper" horizontal isOpen={isOpen}>
            <div id="side-nav">
                <nav>
                    <ul>
                        <SideNavItem
                            id="nav-dashboard"
                            text="Dashboard"
                            onClick={onSelectFeature}
                        />
                        <SideNavItem
                            id="nav-site-status-log"
                            text="Site Status Log"
                            onClick={onSelectFeature}
                            disabled={true}
                        />
                        <SideNavItem
                            id="nav-station-log"
                            text="Station Log"
                            onClick={onSelectFeature}
                        />
                        <SideNavItem
                            id="nav-checklist"
                            text="Checklist"
                            onClick={onSelectFeature}
                        />
                        <SideNavHeader id="nav-data" text="Data" onClick={null}>
                            <SideNavItem
                                id="nav-stripchart"
                                text="Strip Charts"
                                onClick={onSelectFeature}
                            />
                        </SideNavHeader>
                        <SideNavItem
                            id="nav-administration"
                            text="Administration"
                            onClick={onSelectFeature}
                        />
                    </ul>
                </nav>
            </div>
        </Collapse>
    );
}

function SideNavItem({ id, text, onClick, disabled }) {
    const { renderControlledComponent } = useContext(RenderContext);

    return renderControlledComponent(
        <li id={id} onClick={onClick} disabled={disabled}>
            {text}
        </li>
    );
}

function SideNavHeader({ id, text, onClick, disabled, children }) {
    const { renderControlledComponent } = useContext(RenderContext);

    return renderControlledComponent(
        <div id={id} className="side-nav-section">
            <div className="side-nav-row">{text}</div>
            <ul>
                {children}
            </ul>
        </div>
    );
}

export default SideNav;
