import { createContext, useEffect, useState } from "react";
import axios from "axios";
import { pdfjs } from "react-pdf";

import "./styles/App.css";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";

import { AUTH_URL, RENDER_URL, SITE_URL, corsConfig } from "./serverConfig";
import Header from "./Header";
import Main from "./Main";
import { checkLandscape, checkMobile } from "./util/globals";
import AuthLanding from "./auth/AuthLanding";

export const ModalContext = createContext(null);
export const RestContext = createContext(null);
export const RenderContext = createContext(null);
export const WindowContext = createContext(null);
pdfjs.GlobalWorkerOptions.workerSrc = new URL(
    "pdfjs-dist/build/pdf.worker.min.js",
    import.meta.url
).toString();

function App() {
    const [user, setUser] = useState();
    const [renderedElements, setRenderedElements] = useState([]);
    const [siteList, setSiteList] = useState([]);
    const [openFeature, setOpenFeature] = useState("dashboard");
    const [isMobile, setIsMobile] = useState(checkMobile());
    const [isLandscape, setIsLandscape] = useState(true);
    const [navOpen, setNavOpen] = useState(false);
    const [siteDetailsOpen, setSiteDetailsOpen] = useState(false);
    const [invalid, setInvalid] = useState(false);
    const [waiting, setWaiting] = useState(false);
    const [openModal, setOpenModal] = useState(null);

    const toggleNav = () => setNavOpen(!navOpen);

    window.onresize = () => {
        setIsMobile(checkMobile());
        setIsLandscape(checkLandscape());
    };

    useEffect(() => {
        axios
            .get(AUTH_URL + "/login/relog", corsConfig)
            .then((response) => {
                if (response.data !== "") {
                    setUser(response.data);
                }
            })
            .catch((error) => {
                console.error(error);
            });
    }, []);

    useEffect(() => {
        if (user !== undefined) {
            axios
                .get(SITE_URL + "/list", corsConfig)
                .then((response) => {
                    const sites = [
                        { fullName: "All Sites", siteId: "all" },
                    ].concat(response.data);
                    setSiteList(sites);
                })
                .catch((error) => {
                    console.error(error);
                });

            loadRenderControl();
        }
    }, [user]);

    const handleLogin = (formData) => {
        const payload = {
            username: formData["username"],
            password: formData["password"],
        };
        axios
            .post(AUTH_URL + "/login", payload, corsConfig)
            .then((response) => {
                setUser(response.data);
                setWaiting(false);
            })
            .catch((error) => {
                setInvalid(true);
                setWaiting(false);
                console.error(error);
            });
    };

    const handleLogout = () => {
        setWaiting(true);
        axios
            .post(AUTH_URL + "/logout", null, corsConfig)
            .then(() => {
                setUser();
                setWaiting(false);
            })
            .catch((error) => {
                console.log(error);
                setWaiting(false);
            });
    };

    const sendGetRequest = async (
        url,
        callback = (response) => null,
        errorCallback = (response) => null
    ) => {
        await axios
            .get(url, corsConfig)
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.error(error);
                errorCallback(error);
                if (error.response && error.response.status === 401) {
                    handleLogout();
                }
            });
    };

    const sendPostRequest = async (
        url,
        payload,
        callback = (response) => null,
        errorCallback = (response) => null
    ) => {
        await axios
            .post(url, payload, corsConfig)
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.error(error);
                errorCallback(error);
                if (error.response && error.response.status === 401) {
                    handleLogout();
                }
            });
    };

    const sendDeleteRequest = async (
        url,
        callback = (response) => null,
        errorCallback = (response) => null
    ) => {
        await axios
            .delete(url, corsConfig)
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.error(error);
                errorCallback(error);
                if (error.response && error.response.status === 401) {
                    handleLogout();
                }
            });
    };

    const sendGetRequestWithOptions = async (
        url,
        options = {},
        callback = (response) => null,
        errorCallback = (response) => null
    ) => {
        await axios
            .get(url, { ...corsConfig, ...options })
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.error(error);
                errorCallback(error);
                if (error.response && error.response.status === 401) {
                    handleLogout();
                }
            });
    };

    const sendPostRequestWithOptions = async (
        url,
        payload,
        options = {},
        callback = (response) => null,
        errorCallback = (response) => null
    ) => {
        await axios
            .post(url, payload, { ...corsConfig, ...options })
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.error(error);
                errorCallback(error);
                if (error.response && error.response.status === 401) {
                    handleLogout();
                }
            });
    };

    const sendDeleteRequestWithOptions = async (
        url,
        options = {},
        callback = (response) => null,
        errorCallback = (response) => null
    ) => {
        await axios
            .delete(url, { ...corsConfig, ...options })
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.error(error);
                errorCallback(error);
                if (error.response && error.response.status === 401) {
                    handleLogout();
                }
            });
    };

    const loadRenderControl = () => {
        sendGetRequest(RENDER_URL, (response) => {
            setRenderedElements(response.data);
        });
    };

    const renderControlledComponent = (element) => {
        const elementId = element.props.id;
        if (renderedElements.includes(elementId)) {
            return element;
        } else {
            return null;
        }
    };

    const getMain = () => {
        if (user === undefined) {
            return (
                <AuthLanding
                    setInvalid={setInvalid}
                    invalid={invalid}
                    handleLogin={handleLogin}
                    waiting={waiting}
                    setWaiting={setWaiting}
                />
            );
        } else {
            return (
                <Main
                    siteList={siteList}
                    openFeature={openFeature}
                    isMobile={isMobile}
                    siteDetailsOpen={siteDetailsOpen}
                    setSiteDetailsOpen={setSiteDetailsOpen}
                    user={user}
                    navOpen={navOpen}
                    onSelectFeature={onSelectFeature}
                />
            );
        }
    };

    const onSelectFeature = (event) => {
        const featureName = event.target.id.substring(
            event.target.id.indexOf("-") + 1
        );
        setOpenFeature(featureName);
        setSiteDetailsOpen(false);
    };

    return (
        <div id="App">
            <WindowContext.Provider value={{ isMobile, isLandscape }}>
                <RestContext.Provider
                    value={{
                        sendGetRequest: sendGetRequest,
                        sendPostRequest: sendPostRequest,
                        sendDeleteRequest: sendDeleteRequest,
                        sendGetRequestWithOptions: sendGetRequestWithOptions,
                        sendPostRequestWithOptions: sendPostRequestWithOptions,
                        sendDeleteRequestWithOptions:
                            sendDeleteRequestWithOptions,
                    }}
                >
                    <ModalContext.Provider
                        value={{
                            openModal: openModal,
                            setOpenModal: setOpenModal,
                        }}
                    >
                        <RenderContext.Provider
                            value={{
                                renderControlledComponent:
                                    renderControlledComponent,
                                refreshRenderControl: loadRenderControl,
                            }}
                        >
                            <Header
                                user={user}
                                setOpenFeature={setOpenFeature}
                                isMobile={isMobile}
                                setSiteDetailsOpen={setSiteDetailsOpen}
                                handleLogout={handleLogout}
                                navOpen={navOpen}
                                setNavOpen={setNavOpen}
                                toggleNav={toggleNav}
                                onSelectFeature={onSelectFeature}
                            />
                            {getMain()}
                        </RenderContext.Provider>
                    </ModalContext.Provider>
                </RestContext.Provider>
            </WindowContext.Provider>
        </div>
    );
}

export default App;
