import '../../styles/Data.css';

import StripChart from "./StripChart";

function Data({ selectedSite }) {
    return <div id="data">
        <StripChart selectedSite={selectedSite} />
    </div>;
}

export default Data;
